import React from "react";
import { Layout, Menu, Modal, Image } from "antd";
import { Link } from "react-router-dom"
import Routes from "./routes";
import HeaderBar from "../components/HeaderBar";
import config from "../config/index";
import ModelManager from "../domain/ModelManager";
import I18n from "../language/I18n";

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedKeys: ["/sys/home"],
        };
        global.setRegions = this.setRegions.bind(this);
    }

    // UNSAFE_componentWillMount() {
    // this.autoPlayTest();
    // }
    componentDidMount() {
        global.EE.addListener("MENU_CHANGE", this.onMenuChange);
        global.EE.addListener("HEADER_TIP_JUMP", this.onHeaderTipJump);
        let menuKey = localStorage.getItem("selectedMenuKeys");
        console.log("menuKey", menuKey)
        if (menuKey) {
            this.setState({ selectedKeys: [menuKey] });
        }
    }
    componentWillUnmount() {
        global.EE.removeListener("MENU_CHANGE", this.onMenuChange);
        global.EE.removeListener("HEADER_TIP_JUMP", this.onHeaderTipJump);
    }
    setRegions = (regions) => {
        ModelManager.RegionList = regions;
        ModelManager.RegionMap = {};
        for (let i = 0; i < regions.length; i++) {
            const elem = regions[i];
            ModelManager.RegionMap[elem.id] = elem;
        }
        console.log("ModelManager.RegionMap", ModelManager.RegionMap)
        this.forceUpdate();
    }
    play = (context, decodeBuffer) => {
        // 调用resume恢复播放
        context.resume();
        let source = context.createBufferSource();
        source.buffer = decodeBuffer;
        source.connect(context.destination);
        // 从0s开始播放
        source.start(0);
    };
    requestAudio = (url) => {
        return new Promise(resolve => {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            // 这里需要设置xhr response的格式为arraybuffer
            // 否则默认是二进制的文本格式
            xhr.responseType = 'arraybuffer';
            xhr.onreadystatechange = function () {
                // 请求完成，并且成功
                if (xhr.readyState === 4 && xhr.status === 200) {
                    resolve(xhr.response);
                }
            };
            xhr.send();
        });
    };
    setCustomerAudio = async () => {
        let audioPath = global.TemplateUrl + "files/audio2.mp3";
        // Safari是使用webkit前缀
        this.CustomerAudioContext = new (window.AudioContext || window.webkitAudioContext)();
        // 请求音频数据
        let audioMedia = await this.requestAudio(audioPath);
        // 进行decode和play
        this.CustomerAudioContext.decodeAudioData(audioMedia, decode => {
            this.CustomerAudioDecode = decode;
        });
    };
    setAudio = async (audioPath) => {
        // Safari是使用webkit前缀
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        // 请求音频数据
        let audioMedia = await this.requestAudio(audioPath);
        // 进行decode和play
        this.audioContext.decodeAudioData(audioMedia, decode => {
            this.audioDecode = decode;
        });
    };
    testAutoPlay() {
        // 返回一个promise以告诉调用者检测结果
        return new Promise(resolve => {
            let audio = document.createElement('audio');
            // require一个本地文件，会变成base64格式
            // audio.src = require('@/assets/empty-audio.mp3');
            audio.src = require("../assets/audio/noSound.mp3");
            document.body.appendChild(audio);
            let autoplay = true;
            // play返回的是一个promise
            audio.play().then(() => {
                // 支持自动播放
                autoplay = true;
            }).catch(err => {
                // 不支持自动播放
                autoplay = false;
            }).finally(() => {
                audio.remove();
                resolve(autoplay);
            });
        });
    }
    autoPlayTest = async () => {
        this.autoPlay = await this.testAutoPlay();
        console.log("autoPlay", this.autoPlay);
        if (!this.autoPlay) {
            Modal.info({
                title: I18n.t("提示"),
                content: I18n.t("浏览器当前无法播放声音，请点击确定按钮，以便及时接收到用户信息。"),
                okText: I18n.t("确定")
            });

        }
    }
    onHeaderTipJump = (value) => {
        console.log("onHeaderTipJump value", value)
        let pathName, emitKey;
        if (value.code === "CHECKOUT") {
            pathName = "/sys/checkout";
            emitKey = "TIP_JUMP_CHECKOUT";
        } else if (["SORT", "DISMANTLE", "DISMANTLE_CHECKOUT"].includes(value.code)) {
            pathName = "/sys/sortingApp";
            emitKey = "TIP_JUMP_SORT";
        }
        if (pathName) {
            this.props.history.push({ pathname: pathName });
            this.onMenuChange({ key: pathName, keyPath: [pathName] })
            setTimeout(() => {
                global.EE.emitEvent(emitKey, [value]);
            }, 200)
        }
    }
    onMenuChange = (key) => {
        console.log("onMenuChange key", key)
        localStorage.setItem("selectedMenuKeys", key.key);
        this.setState({ selectedKeys: key.keyPath })
    }
    setMenuItems = (menus) => {
        let items = [];
        for (let i = 0; i < menus.length; i++) {
            const menu = menus[i];
            if (menu.showMenu != null && !menu.showMenu) {
                continue;
            }
            items.push({
                key: menu.key,
                label: (
                    <Link to={menu.key}>
                        <span style={{ fontWeight: '800', fontSize: 14, paddingLeft: 5, paddingRight: 5, }}>
                            <span style={{ marginRight: 5 }}>{menu.icon}</span>
                            {I18n.t(menu.name)}
                        </span>
                    </Link>
                ),
            })
        }
        console.log("menus items", items)
        return items;
    };
    initMenus = () => {
        var menus = config.menus || [];
        const realMenu = [];
        const loginUserStation = ModelManager.loginUserStation();
        const baoyangMenu = ['/sys/maintenance', '/sys/coffeeAppQuery', '/sys/followup'];
        if (ModelManager.isAdmin() === false) {
            for (var i = 0; i < menus.length; i++) {
                let menu = menus[i];
                if (ModelManager.isZBPDY()) {
                    if (menu.key === '/sys/checkout') {
                        this.state.selectedKeys = ['/sys/checkout'];
                        realMenu.push(menu);
                    }
                } else if (ModelManager.isDataImport()) {
                    if (menu.key === "/sys/order") {
                        this.state.selectedKeys = ['/sys/order'];
                        realMenu.push(menu);
                    }
                } else if (ModelManager.isAgency()) {
                    if (["/sys/home", "/sys/fixApp"].includes(menu.key)) {
                        realMenu.push(menu);
                    }
                } else if (ModelManager.isAudit()) {
                    if (["/sys/audit"].includes(menu.key)) {
                        this.state.selectedKeys = ['/sys/audit'];
                        realMenu.push(menu);
                    }
                } else if (ModelManager.isSafe()) {
                    if (["/sys/setting"].includes(menu.key)) {
                        this.state.selectedKeys = ['/sys/setting'];
                        realMenu.push(menu);
                    } else if (menu.key === '/sys/audit') {
                        if (ModelManager.loginUser && ModelManager.loginUser.canAudit) {
                            realMenu.push(menu);
                        }
                    }
                } else if (ModelManager.userIsSortingRegion()) {
                    if (menu.key === '/sys/sortingApp') {
                        this.state.selectedKeys = ['/sys/sortingApp'];
                        realMenu.push(menu);
                    }
                } else {
                    if (menu.key !== "/sys/setting") {
                        if (baoyangMenu.includes(menu.key) && ModelManager.loginUser && ModelManager.loginUser.loginName === "admin2") {
                            continue;
                        }
                        if (['/sys/awards', '/sys/followup', '/sys/replace', '/sys/followup_400'].includes(menu.key)) {
                            if (ModelManager.userIsZB()) {
                                realMenu.push(menu);
                            }
                        } else if (['/sys/maintenance', '/sys/coffeeAppQuery'].includes(menu.key)) {
                            if ((loginUserStation && loginUserStation.byAuth) || ModelManager.isAdmin()) {
                                realMenu.push(menu);
                            }
                        } else if (menu.key === '/sys/audit') {
                            if (ModelManager.loginUser && (ModelManager.loginUser.canAudit)) {
                                realMenu.push(menu);
                            }
                        } else if (menu.key === '/sys/order') {
                            if (ModelManager.orderAuditAuth()) {
                                realMenu.push(menu);
                            }
                        } else if (menu.key === '/sys/sortingApp') {
                            if (ModelManager.userIsSortingRegion() || ModelManager.userIsZB()) {
                                realMenu.push(menu);
                            }
                        } else if (!ModelManager.isAudit()) {
                            realMenu.push(menu);
                        }
                    } else {
                        if (ModelManager.canViewSetting()) {
                            realMenu.push(menu);
                        }
                    }
                }
            }
            menus = realMenu;
        }
        if (menus.length === 0 && !global.isDev) {
            global.EE.emitEvent("CLEAR_LOGOUT");
        }
        return menus;
    }
    render() {
        const menus = this.initMenus();
        console.log("menus***", menus)
        return (
            <div id="page">
                <Layout>
                    <div style={{ paddingBottom: 10, background: '#1C518B', position: 'relative' }}>
                        <Layout.Header style={{ height: 80, background: '#1C518B', position: 'relative', zIndex: 9, padding: "0px" }}>
                            <div style={{ position: 'absolute', left: 0, bottom: 0, display: 'flex', alignItems: 'center', width: '100%', opacity: 0.3 }}>
                                <Image width={'80%'} src={require("../assets/images/top-bg2.png").default} preview={false} />
                            </div>
                            <HeaderBar />
                        </Layout.Header>
                        <Menu mode="horizontal"
                            style={{ lineHeight: "40px", position: 'relative', zIndex: 9 }}
                            onClick={(key) => this.onMenuChange(key)}
                            selectedKeys={this.state.selectedKeys}
                            items={this.setMenuItems(menus)}
                        />
                    </div>
                    <Layout.Content style={{ minHeight: global.MainContentHeight }}>
                        <Routes />
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}
